<template>
  <div id="nav">
    <div class="border-nav relative z-50">
      <div class="max-w-screen-xl 2xl:max-w-screen-2xl mx-auto px-4 lg:px-8 py-4 lg:py-8">
        <div class="md:w-full relative flex justify-between items-center lg:gap-x-12">
          <div class="relative mb-0">
            <slot name="brand" />
          </div>
          <div class="slide-menu  hidden lg:flex items-center gap-x-8 justify-end md:w-full text-white font-headline text-base">
            <a aria-label="mobile number" href="Tel:028 8765 8123" class="inline-flex text-lg hover:text-primary transition-all duration-300 ease-in-out text-white items-center gap-x-3">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5" viewBox="0 0 16.001 16.081">
                <path id="phone" d="M.1,4.3A4.124,4.124,0,0,1,2.7.3a1.647,1.647,0,0,1,2,.4A34.572,34.572,0,0,1,6.9,3.8,1.819,1.819,0,0,1,6.7,6,1.633,1.633,0,0,0,7,8.4l.7.7c1.3,1.2,1.3,1.1,2.9.4a2.51,2.51,0,0,1,1.9.1,15.86,15.86,0,0,1,2.7,1.8c1.1.9,1.3,2.1,0,3.4a4.04,4.04,0,0,1-4.6,1.1A18.133,18.133,0,0,1,.3,5.7c0-.2-.1-.3-.1-.5A2.769,2.769,0,0,0,.1,4.3Zm1.1.1c.2.6.4,1.4.7,2.1a17.392,17.392,0,0,0,8.3,8.2,3.3,3.3,0,0,0,4.2-.7c.6-.7.6-1.3-.2-1.8l-2.1-1.5a1.007,1.007,0,0,0-1.5.1,1.97,1.97,0,0,1-2.9-.1c-.5-.5-1-.9-1.5-1.4C4.9,7.9,4.8,7.2,5.7,5.5a1.651,1.651,0,0,0-.1-1.9c-.3-.4-.5-.8-.7-1.1-1.2-2-2.2-1.4-3.2.2A4.248,4.248,0,0,0,1.2,4.4Z" transform="translate(-0.1 -0.136)" fill="currentColor"/>
              </svg>
              028 8765 8123
            </a>
            <slot name="shopping" />
            <button name="main menu" aria-label="main menu" type="button" @click="isActive = !isActive; isSlid = true;addo();addh();" class="text-center text-primary font-lato text-sm uppercase tracking-widest">
              <div id="nav-icon4">
                <span></span>
                <span></span>
                <span></span>
                <p class="text-white absolute -bottom-7">MENU</p>
              </div>
              </button>
          </div>
          <div class="h-6 lg:hidden">
            <a aria-label="mobile number" href="Tel:028 8765 8123" class="inline-flex text-lg mr-6 hover:text-primary transition-all duration-300 ease-in-out text-white items-center gap-x-3">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5" viewBox="0 0 16.001 16.081">
                <path id="phone" d="M.1,4.3A4.124,4.124,0,0,1,2.7.3a1.647,1.647,0,0,1,2,.4A34.572,34.572,0,0,1,6.9,3.8,1.819,1.819,0,0,1,6.7,6,1.633,1.633,0,0,0,7,8.4l.7.7c1.3,1.2,1.3,1.1,2.9.4a2.51,2.51,0,0,1,1.9.1,15.86,15.86,0,0,1,2.7,1.8c1.1.9,1.3,2.1,0,3.4a4.04,4.04,0,0,1-4.6,1.1A18.133,18.133,0,0,1,.3,5.7c0-.2-.1-.3-.1-.5A2.769,2.769,0,0,0,.1,4.3Zm1.1.1c.2.6.4,1.4.7,2.1a17.392,17.392,0,0,0,8.3,8.2,3.3,3.3,0,0,0,4.2-.7c.6-.7.6-1.3-.2-1.8l-2.1-1.5a1.007,1.007,0,0,0-1.5.1,1.97,1.97,0,0,1-2.9-.1c-.5-.5-1-.9-1.5-1.4C4.9,7.9,4.8,7.2,5.7,5.5a1.651,1.651,0,0,0-.1-1.9c-.3-.4-.5-.8-.7-1.1-1.2-2-2.2-1.4-3.2.2A4.248,4.248,0,0,0,1.2,4.4Z" transform="translate(-0.1 -0.136)" fill="currentColor"/>
              </svg>
            </a>
            <button name="main menu" aria-label="main menu" type="button" @click="isMenu = !isMenu; isActive = !isActive; isSlid = true; isSlid = true;addo();addh();" class="text-center text-primary font-lato text-sm uppercase tracking-widest	">
              <div id="nav-icon4" :class="{ open: isActive }">
                <span></span>
                <span></span>
                <span></span>
                <p class="text-white absolute -bottom-7">MENU</p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div :class="{ desktopmenu: isActive }"  class="absolute overflow-hidden z-40 main-show w-screen hidden lg:block h-screen bg-secondary top-0 right-0 left-0 bottom-0 invisi">
      <!-- <img src="/app/uploads/2022/11/grey-lines.svg" class="absolute left-0 right-0 w-screen bottom-12" alt="Lines"> -->
      <div class="max-w-screen-xl h-full flex justify-center item-center 2xl:max-w-screen-2xl relative mt-28 mx-auto px-4 lg:px-8 py-4 lg:py-8">
        <div class="" style="max-height:450px;">
          <div class="text-white">
            <slot name="mainmenu" />
            <div class="mt-8 flex justify-center gap-x-5 items-center">
              <a aria-label="facebook social Icon" href="https://www.facebook.com/profile.php?id=100063716281886" class="footer-link w-6"><icon-facebooktwo></icon-facebooktwo></a>
              <a aria-label="Instagram social Icon" href="https://www.instagram.com/viberoptix/" class="footer-link w-6"><icon-instagram></icon-instagram></a>
              <a aria-label="Twitter social Icon" href="https://twitter.com/Viber_optix" class="footer-link w-6"><icon-twitter></icon-twitter></a>
              <a aria-label="Linkedin social Icon" href="https://www.linkedin.com/company/viberoptix/" class="footer-link w-6"><icon-linkedin></icon-linkedin></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="{ desktopmenu: isMenu }"  class="absolute w-screen block lg:hidden h-screen bg-secondary top-0 right-0 left-0 bottom-0 invisib">
      <div class="h-full relative overflow-x-hidden pb-12">
        <img src="/app/uploads/2022/11/grey-lines.svg" class="absolute left-0 right-0 w-screen bottom-12" alt="Lines">
        <div class="px-4 text-center mt-28">
          <slot name="mainmenu" />
          <div class="flex justify-center gap-x-8 my-4 ">
            <slot name="shopping" />
          </div>
          <div class="mt-8 flex justify-center gap-x-3 items-center">
            <a aria-label="facebook social Icon" href="https://www.facebook.com/profile.php?id=100063716281886" class="footer-link w-6"><icon-facebooktwo></icon-facebooktwo></a>
            <a aria-label="Instagram social Icon" href="https://www.instagram.com/viberoptix/" class="footer-link w-6"><icon-instagram></icon-instagram></a>
            <a aria-label="Twitter social Icon" href="https://twitter.com/Viber_optix" class="footer-link w-6"><icon-twitter></icon-twitter></a>
            <a aria-label="Linkedin social Icon" href="https://www.linkedin.com/company/viberoptix/" class="footer-link w-6"><icon-linkedin></icon-linkedin></a>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isOpen: false,
      isOpenLeft: false,
      services:false,
      isActive: false,
      isSlid: true,
      isMenu: false,
    }
  },
  methods: {
    addo () {
    document.querySelector('body').classList.toggle('overflow-hidden')
    },
    addh () {
      document.querySelector('body').classList.toggle('max-h-screen')
    },
    removeo () {
    document.querySelector('body').classList.toggle('overflow-hidden')
    },
    removeh () {
      document.querySelector('body').classList.toggle('max-h-screen')
    },
  }
}
</script>
<style lang="scss">
.invisi{
  margin-top: -105vh;
  transition: ease-in-out 0.5s all;
}
.invisit{
  margin-top: -105vh;
  transition: ease-in-out 0.5s all;
}
.invisib{
  margin-top: -200vh;
  transition: ease-in-out 0.5s all;
}
.desktopmenu{
  margin-top: 0;
}
.slideit{
    animation: slideit 1.5s;
}

@keyframes slideit {
  0% {
    margin-top: -105vh;
  }
  30% {
    margin-top: 0;
  }
  70%{
    margin-top: 0;
  }
  100% {
    margin-top: 105vh;
  }
}
</style>
