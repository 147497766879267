import Vue from 'vue'
window.Vue = Vue
import jquery from 'jquery'
import VueAnimate from 'vue-animate-scroll'
Vue.use(VueAnimate)
import axios from 'axios'
import { extend } from 'vee-validate'
import { required, email, regex } from 'vee-validate/dist/rules'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

import AppFixedHeader from './components/AppFixedHeader'
import ImageSlider from './components/ImageSlider'
import FiberSlider from './components/FiberSlider'
import LogoSlider from './components/LogoSlider'
import ReviewSlider from './components/ReviewSlider'
import BenefitSlider from './components/BenefitSlider'
import StaffSlider from './components/StaffSlider'
import HistorySlider from './components/HistorySlider'
import NewsSlider from './components/NewsSlider'
import AppNavBar from './components/AppNavBar'
import ScrollTop from './components/ScrollTop'
import Modal from './components/Modal'
import ContactForm from './components/ContactForm'
Vue.component('AppFixedHeader', AppFixedHeader)
Vue.component('AppNavBar', AppNavBar)
Vue.component('ScrollTop', ScrollTop)
Vue.component('Modal', Modal)
Vue.component('ImageSlider', ImageSlider)
Vue.component('FiberSlider', FiberSlider)
Vue.component('LogoSlider', LogoSlider)
Vue.component('ReviewSlider', ReviewSlider)
Vue.component('BenefitSlider', BenefitSlider)
Vue.component('StaffSlider', StaffSlider)
Vue.component('HistorySlider', HistorySlider)
Vue.component('NewsSlider', NewsSlider)
Vue.component('ContactForm', ContactForm)


const files = require.context('./components/icons/', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


Vue.prototype.$axios = axios
Vue.config.productionTip = false


extend('required', {
  ...required,
  message: 'The {_field_} is required'
})

extend('email', email)
extend('regex', regex)

extend('min', {
  validate (value, args) {
    return value.length >= args.length
  },
  params: ['length'],
  message: 'The {_field_} field must have at least {length} characters'
})

extend('max', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field may not be greater than {length} characters'
})

extend('length', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field can have only {length} of checked items'
})

window.addEventListener("load", (event) => {
if (document.getElementById('particles-js')) {
  particlesJS('particles-js',

    {
      "particles": {
        "number": {
          "value": 80,
          "density": {
            "enable": true,
            "value_area": 800
          }
        },
        "color": {
          "value": "#ffffff"
        },
        "shape": {
          "type": "circle",
          "stroke": {
            "width": 0,
            "color": "#000000"
          },
          "polygon": {
            "nb_sides": 5
          },
          "image": {
            "src": "img/github.svg",
            "width": 100,
            "height": 100
          }
        },
        "opacity": {
          "value": 0.5,
          "random": false,
          "anim": {
            "enable": false,
            "speed": 1,
            "opacity_min": 0.1,
            "sync": false
          }
        },
        "size": {
          "value": 5,
          "random": true,
          "anim": {
            "enable": false,
            "speed": 30,
            "size_min": 0.1,
            "sync": false
          }
        },
        "line_linked": {
          "enable": true,
          "distance": 150,
          "color": "#ffffff",
          "opacity": 0.4,
          "width": 1
        },
        "move": {
          "enable": true,
          "speed": 3,
          "direction": "none",
          "random": false,
          "straight": false,
          "out_mode": "out",
          "attract": {
            "enable": false,
            "rotateX": 600,
            "rotateY": 1200
          }
        }
      },
      "interactivity": {
        "detect_on": "canvas",
        "events": {
          "onhover": {
            "enable": true,
            "mode": "repulse"
          },
          "onclick": {
            "enable": true,
            "mode": "push"
          },
          "resize": true
        },
        "modes": {
          "grab": {
            "distance": 400,
            "line_linked": {
              "opacity": 1
            }
          },
          "bubble": {
            "distance": 400,
            "size": 40,
            "duration": 3,
            "opacity": 8,
            "speed": 2
          },
          "repulse": {
            "distance": 200
          },
          "push": {
            "particles_nb": 4
          },
          "remove": {
            "particles_nb": 2
          }
        }
      },
      "retina_detect": true,
      "config_demo": {
        "hide_card": false,
        "background_color": "#b61924",
        "background_image": "",
        "background_position": "50% 50%",
        "background_repeat": "no-repeat",
        "background_size": "cover"
      }
    }

  );
}
});
$(document).ready(function(){
	$('#nav-icon4').click(function(){
		$(this).toggleClass('open');
	});
});
