<template>
  <div v-swiper:LogoSlider="settings">
    <div class="swiper-wrapper">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'LogoSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        direction: "horizontal",
        loop: false,
        spaceBetween: 40,
        noSwiping: false,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          clickable: true,
          el: '.gallery-pagination',
        },
        allowTouchMove: true,
        breakpoints: {
          650: {
            slidesPerView: 3,
            spaceBetween: 20,
            noSwiping: false,
            allowTouchMove: true,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 30,
            noSwiping: true,
            allowTouchMove: false,
          }
        }
      }
    }
  }
}
</script>
