<template>
  <div style="overflow-x:clip" v-swiper:HistorySlider="settings">
    <div class="h-1 bg-primary w-screen absolute ">

    </div>
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="flex lg:gap-x-24 justify-center relative w-full gap-x-8 mt-12">
  <div class="swiper-button-prevs z-10">

    <svg xmlns="http://www.w3.org/2000/svg" class="w-20" width="95" height="95" viewBox="0 0 95 95">
      <g id="Group_6943" data-name="Group 6943" transform="translate(-29 -852.363)">
        <g id="Ellipse_2224" data-name="Ellipse 2224" transform="translate(124 947.363) rotate(180)" fill="#121212" stroke="#16cad9" stroke-width="1">
          <circle cx="47.5" cy="47.5" r="47.5" stroke="none"/>
          <circle cx="47.5" cy="47.5" r="47" fill="none"/>
        </g>
        <path id="Path_3312" data-name="Path 3312" d="M13,7l5,5m0,0-5,5m5-5H6" transform="translate(88 911.363) rotate(180)" fill="none" stroke="#16cad9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </g>
    </svg>

  </div>
  <div class="swiper-button-nexts z-10">
    <svg xmlns="http://www.w3.org/2000/svg" width="95" height="95" class="w-20 rotate-180" viewBox="0 0 95 95">
      <g id="Group_6943" data-name="Group 6943" transform="translate(-29 -852.363)">
        <g id="Ellipse_2224" data-name="Ellipse 2224" transform="translate(124 947.363) rotate(180)" fill="#121212" stroke="#16cad9" stroke-width="1">
          <circle cx="47.5" cy="47.5" r="47.5" stroke="none"/>
          <circle cx="47.5" cy="47.5" r="47" fill="none"/>
        </g>
        <path id="Path_3312" data-name="Path 3312" d="M13,7l5,5m0,0-5,5m5-5H6" transform="translate(88 911.363) rotate(180)" fill="none" stroke="#16cad9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </g>
    </svg>


  </div>
</div>
  </div>
</template>
<script>
export default {
  name: 'HistorySlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        direction: "horizontal",
        loop: false,
        spaceBetween: 40,
        noSwiping: false,
        navigation: {
         nextEl: '.swiper-button-nexts',
         prevEl: '.swiper-button-prevs',
        },
        allowTouchMove: true,
        breakpoints: {
          992: {
            slidesPerView: 3,
            spaceBetween: 20,
          }
        }
      }
    }
  }
}
</script>
